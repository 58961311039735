import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createCommentVNode as _createCommentVNode, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { key: 12 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_cmc_ssh_key_input = _resolveComponent("cmc-ssh-key-input")!
  const _component_cmc_form_element = _resolveComponent("cmc-form-element", true)!
  const _component_cmc_accordion = _resolveComponent("cmc-accordion")!
  const _component_cmc_sensitive_text_input = _resolveComponent("cmc-sensitive-text-input")!
  const _component_cmc_text_input = _resolveComponent("cmc-text-input")!
  const _component_cmc_select = _resolveComponent("cmc-select")!
  const _component_cmc_checkbox_group = _resolveComponent("cmc-checkbox-group")!
  const _component_cmc_checkbox = _resolveComponent("cmc-checkbox")!
  const _component_cmc_alert = _resolveComponent("cmc-alert")!
  const _component_cmc_sensitive_text_area = _resolveComponent("cmc-sensitive-text-area")!
  const _component_cmc_text_area = _resolveComponent("cmc-text-area")!
  const _component_cmc_list_select_form_element = _resolveComponent("cmc-list-select-form-element")!
  const _component_cmc_batch_input = _resolveComponent("cmc-batch-input")!
  const _component_cmc_radio_group = _resolveComponent("cmc-radio-group")!
  const _component_cmc_block = _resolveComponent("cmc-block")!

  return (_ctx.formElement.type === 'collapsible')
    ? (_openBlock(), _createBlock(_component_cmc_accordion, {
        key: 0,
        text: _ctx.formElement.label,
        "with-I18n": "",
        "keep-open": _ctx.isErrorPresent
      }, {
        default: _withCtx(() => [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.formElement.children, (fe) => {
            return (_openBlock(), _createElementBlock("div", {
              key: fe.field
            }, [
              (fe.type === 'sshKey')
                ? (_openBlock(), _createBlock(_component_cmc_ssh_key_input, {
                    key: 0,
                    modelValue: _ctx.modelValue as Record<string, any>,
                    formElement: fe,
                    "as-optional": !fe.required,
                    disabled: fe.disabled || _ctx.disabled,
                    errors: _ctx.error,
                    onReload: _cache[0] || (_cache[0] = ($event: any) => (_ctx.$emit('reload', $event))),
                    "onUpdate:modelValue": _ctx.inputChange
                  }, null, 8, ["modelValue", "formElement", "as-optional", "disabled", "errors", "onUpdate:modelValue"]))
                : (_openBlock(), _createBlock(_component_cmc_form_element, {
                    key: 1,
                    "model-value": _ctx.collapsibleValue(fe.field),
                    formElement: fe,
                    disabled: fe.disabled || _ctx.disabled,
                    error: _ctx.collapsibleError(fe.field),
                    "onUpdate:modelValue": ($event: any) => (_ctx.collapsibleValueChange(fe.field, $event)),
                    onReload: _cache[1] || (_cache[1] = ($event: any) => (_ctx.$emit('reload', $event))),
                    onChange: _cache[2] || (_cache[2] = ($event: any) => (_ctx.$emit('change', $event)))
                  }, null, 8, ["model-value", "formElement", "disabled", "error", "onUpdate:modelValue"]))
            ]))
          }), 128))
        ]),
        _: 1
      }, 8, ["text", "keep-open"]))
    : (_openBlock(), _createBlock(_component_cmc_block, { key: 1 }, {
        default: _withCtx(() => [
          (_ctx.isInput && _ctx.isSensitive)
            ? (_openBlock(), _createBlock(_component_cmc_sensitive_text_input, {
                key: 0,
                "obscure-initial-value": !_ctx.formElement.viewable,
                "model-value": _ctx.modelValue == null ? _ctx.modelValue : _ctx.modelValue.toString(),
                type: _ctx.formElement.type,
                label: _ctx.elemLabel,
                "with-label-i18n": "",
                "with-tooltip": _ctx.elemDescLabel,
                "with-tooltip-i18n": "",
                "with-warning-tooltip": _ctx.disabled ? _ctx.elemDisabledDescriptionLabel : '',
                "with-warning-tooltip-i18n": "",
                "with-error-text": _ctx.errorText,
                placeholder: _ctx.elemPlaceholderLabel,
                "with-placeholder-i18n": "",
                disabled: _ctx.formElement.disabled || _ctx.disabled,
                "onUpdate:modelValue": _ctx.inputChange
              }, null, 8, ["obscure-initial-value", "model-value", "type", "label", "with-tooltip", "with-warning-tooltip", "with-error-text", "placeholder", "disabled", "onUpdate:modelValue"]))
            : (_ctx.isInput)
              ? (_openBlock(), _createBlock(_component_cmc_text_input, {
                  key: 1,
                  "model-value": _ctx.modelValue == null ? _ctx.modelValue : _ctx.modelValue.toString(),
                  type: _ctx.formElement.type,
                  label: _ctx.elemLabel,
                  "as-optional": !_ctx.formElement.required,
                  "with-label-i18n": "",
                  "with-tooltip": _ctx.elemDescLabel,
                  "with-tooltip-i18n": "",
                  "with-warning-tooltip": _ctx.disabled ? _ctx.elemDisabledDescriptionLabel : '',
                  "with-warning-tooltip-i18n": "",
                  "with-error-text": _ctx.errorText,
                  placeholder: _ctx.elemPlaceholderLabel,
                  "with-placeholder-i18n": "",
                  disabled: _ctx.formElement.disabled || _ctx.disabled,
                  "onUpdate:modelValue": _ctx.inputChange
                }, null, 8, ["model-value", "type", "label", "as-optional", "with-tooltip", "with-warning-tooltip", "with-error-text", "placeholder", "disabled", "onUpdate:modelValue"]))
              : (_ctx.isSelect)
                ? (_openBlock(), _createBlock(_component_cmc_select, {
                    key: 2,
                    "as-tag": _ctx.formElement.type === 'tags',
                    "as-multi": _ctx.formElement.type === 'tags' || _ctx.formElement.type === 'multi-select-checkbox',
                    label: _ctx.elemLabel,
                    "as-optional": !_ctx.formElement.required,
                    "with-label-i18n": "",
                    "with-tooltip": _ctx.elemDescLabel,
                    "with-tooltip-i18n": "",
                    "model-value": _ctx.selectValue,
                    options: _ctx.selectOptions,
                    "with-placeholder": _ctx.formElement.disabled || _ctx.disabled ? _ctx.elemDisabledDescriptionLabel : _ctx.elemPlaceholderLabel,
                    "with-placeholder-i18n": "",
                    "allow-empty": !_ctx.formElement.required,
                    disabled: _ctx.formElement.disabled || _ctx.disabled,
                    "with-error-text": _ctx.errorText,
                    withEmptyLabel: _ctx.formElement.emptyLabel,
                    "with-empty-label-i18n": "",
                    withReadOnlyEmptyLabel: _ctx.formElement.readOnlyEmptyLabel,
                    "onUpdate:modelValue": _ctx.inputChange
                  }, null, 8, ["as-tag", "as-multi", "label", "as-optional", "with-tooltip", "model-value", "options", "with-placeholder", "allow-empty", "disabled", "with-error-text", "withEmptyLabel", "withReadOnlyEmptyLabel", "onUpdate:modelValue"]))
                : (_ctx.formElement.type === 'checkboxes')
                  ? (_openBlock(), _createBlock(_component_cmc_checkbox_group, {
                      key: 3,
                      "model-value": _ctx.checkboxGroupModelValue,
                      options: _ctx.checkboxGroupOptions,
                      label: _ctx.elemLabel,
                      "as-optional": !_ctx.formElement.required,
                      "with-label-i18n": "",
                      "with-tooltip": _ctx.elemDescLabel,
                      "with-tooltip-i18n": "",
                      "with-warning-tooltip": _ctx.disabled ? _ctx.elemDisabledDescriptionLabel : '',
                      "with-warning-tooltip-i18n": "",
                      "with-error-text": _ctx.errorText,
                      disabled: _ctx.formElement.disabled || _ctx.disabled,
                      "onUpdate:modelValue": _ctx.inputChange
                    }, null, 8, ["model-value", "options", "label", "as-optional", "with-tooltip", "with-warning-tooltip", "with-error-text", "disabled", "onUpdate:modelValue"]))
                  : (_ctx.formElement.type === 'checkbox')
                    ? (_openBlock(), _createBlock(_component_cmc_checkbox, {
                        key: 4,
                        modelValue: new String(_ctx.modelValue).toLowerCase() === 'true',
                        label: _ctx.elemLabel,
                        "with-label-i18n": "",
                        "with-tooltip": _ctx.elemDescLabel,
                        "with-tooltip-i18n": "",
                        "with-warning-tooltip": _ctx.disabled ? _ctx.elemDisabledDescriptionLabel : '',
                        "with-warning-tooltip-i18n": "",
                        "with-error-text": _ctx.errorText,
                        disabled: _ctx.formElement.disabled || _ctx.disabled,
                        "as-toggle": _ctx.formElement.asToggle,
                        reversed: _ctx.formElement.reversed,
                        "as-header": _ctx.formElement.asToggle,
                        heading: "h4",
                        "onUpdate:modelValue": _ctx.inputChange
                      }, null, 8, ["modelValue", "label", "with-tooltip", "with-warning-tooltip", "with-error-text", "disabled", "as-toggle", "reversed", "as-header", "onUpdate:modelValue"]))
                    : (_ctx.formElement.type === 'message')
                      ? (_openBlock(), _createBlock(_component_cmc_alert, {
                          key: 5,
                          text: _ctx.formElement.label,
                          "with-i18n": "",
                          "with-full-width": "",
                          "with-outline": false,
                          "as-warning": _ctx.formElement.alertType === 'WARNING',
                          color: _ctx.alertColour
                        }, null, 8, ["text", "as-warning", "color"]))
                      : (_ctx.isTextArea && !_ctx.isCodeEditor && _ctx.isSensitive)
                        ? (_openBlock(), _createBlock(_component_cmc_sensitive_text_area, {
                            key: 6,
                            "obscure-initial-value": !_ctx.formElement.viewable,
                            "model-value": _ctx.modelValue,
                            label: _ctx.elemLabel,
                            "with-label-i18n": "",
                            withTooltip: _ctx.elemDescLabel,
                            "with-tooltip-i18n": "",
                            "with-warning-tooltip": _ctx.disabled ? _ctx.elemDisabledDescriptionLabel : '',
                            "with-warning-tooltip-i18n": "",
                            "with-placeholder": _ctx.elemPlaceholderLabel,
                            "with-placeholder-i18n": "",
                            "with-error-text": _ctx.errorText,
                            disabled: _ctx.formElement.disabled || _ctx.disabled,
                            "onUpdate:modelValue": _ctx.inputChange
                          }, null, 8, ["obscure-initial-value", "model-value", "label", "withTooltip", "with-warning-tooltip", "with-placeholder", "with-error-text", "disabled", "onUpdate:modelValue"]))
                        : (_ctx.isTextArea)
                          ? (_openBlock(), _createBlock(_component_cmc_text_area, {
                              key: 7,
                              "model-value": _ctx.modelValue,
                              label: _ctx.elemLabel,
                              "as-optional": !_ctx.formElement.required,
                              "with-label-i18n": "",
                              withTooltip: _ctx.elemDescLabel,
                              "with-tooltip-i18n": "",
                              "with-warning-tooltip": _ctx.disabled ? _ctx.elemDisabledDescriptionLabel : '',
                              "with-warning-tooltip-i18n": "",
                              "with-placeholder": _ctx.elemPlaceholderLabel,
                              "with-placeholder-i18n": "",
                              "with-error-text": _ctx.errorText,
                              disabled: _ctx.formElement.disabled || _ctx.disabled,
                              "as-code-editor": _ctx.isCodeEditor,
                              language: _ctx.formElement.language,
                              "onUpdate:modelValue": _ctx.inputChange
                            }, null, 8, ["model-value", "label", "as-optional", "withTooltip", "with-warning-tooltip", "with-placeholder", "with-error-text", "disabled", "as-code-editor", "language", "onUpdate:modelValue"]))
                          : (_ctx.formElement.type === 'listSelect')
                            ? (_openBlock(), _createBlock(_component_cmc_list_select_form_element, {
                                key: 8,
                                label: _ctx.elemLabel,
                                "with-tooltip": _ctx.elemDescLabel,
                                category: _ctx.category,
                                modelValue: _ctx.modelValue,
                                config: _ctx.selectedConfig,
                                "form-element": _ctx.formElement,
                                disabled: _ctx.formElement.disabled || _ctx.disabled,
                                "onUpdate:modelValue": _ctx.inputChange,
                                "onUpdate:category": _ctx.categoryChange,
                                "onUpdate:config": _ctx.configChange
                              }, null, 8, ["label", "with-tooltip", "category", "modelValue", "config", "form-element", "disabled", "onUpdate:modelValue", "onUpdate:category", "onUpdate:config"]))
                            : (_ctx.formElement.type === 'sshKey')
                              ? (_openBlock(), _createBlock(_component_cmc_ssh_key_input, {
                                  key: 9,
                                  modelValue: _ctx.modelValue as Record<string, any>,
                                  formElement: _ctx.formElement,
                                  disabled: _ctx.formElement.disabled || _ctx.disabled,
                                  errors: _ctx.error,
                                  "onUpdate:modelValue": _ctx.inputChange
                                }, null, 8, ["modelValue", "formElement", "disabled", "errors", "onUpdate:modelValue"]))
                              : (_ctx.isBatch)
                                ? (_openBlock(), _createBlock(_component_cmc_batch_input, {
                                    key: 10,
                                    "model-value": _ctx.modelValue == null ? _ctx.modelValue : _ctx.modelValue.toString(),
                                    "form-element": _ctx.formElement,
                                    errors: _ctx.error,
                                    disabled: _ctx.formElement.disabled || _ctx.disabled,
                                    "onUpdate:modelValue": _ctx.inputChange,
                                    "onUpdate:batch": _ctx.batchOptionChange
                                  }, null, 8, ["model-value", "form-element", "errors", "disabled", "onUpdate:modelValue", "onUpdate:batch"]))
                                : (_ctx.isRadio)
                                  ? (_openBlock(), _createBlock(_component_cmc_radio_group, {
                                      key: 11,
                                      label: _ctx.formElement.label,
                                      "with-label-i18n": "",
                                      withTooltip: _ctx.elemDescLabel,
                                      "with-tooltip-i18n": "",
                                      "with-warning-tooltip": _ctx.disabled ? _ctx.elemDisabledDescriptionLabel : '',
                                      "with-warning-tooltip-i18n": "",
                                      options: _ctx.radioGroupOptions,
                                      "model-value": _ctx.modelValue,
                                      "onUpdate:modelValue": _ctx.inputChange
                                    }, null, 8, ["label", "withTooltip", "with-warning-tooltip", "options", "model-value", "onUpdate:modelValue"]))
                                  : (_openBlock(), _createElementBlock("div", _hoisted_1, " TODO: " + _toDisplayString(_ctx.formElement.type), 1))
        ]),
        _: 1
      }))
}