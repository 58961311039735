import store from '@/store';
import socket from '@/socket';
import { getConnectionByServiceCode } from '@/utils/service';
import { loadLanguageAsync } from '@/i18n';
import PageNotFound from '../components/PageNotFound';

const ResourceView = () => import(/* webpackChunkName: "mainApp" */ './components/ResourceView');
const WorkspacePage = () => import(/* webpackChunkName: "mainApp" */ './components/WorkspacePage');
const ServicePage = () => import(/* webpackChunkName: "mainApp" */ './ServicePage');
const EnvironmentList = () => import(/* webpackChunkName: "mainApp" */ './components/EnvironmentList');
const EnvironmentDashboard = () => import(/* webpackChunkName: "mainApp" */ './components/EnvironmentDashboard');
const EditEnvironmentMembers = () => import(/* webpackChunkName: "mainApp" */ './components/members/EditEnvironmentMembers');
const CreateEnvironment = () => import(/* webpackChunkName: "mainApp" */ './components/environments/CreateEnvironment');
const EditEnvironment = () => import(/* webpackChunkName: "mainApp" */ './components/environments/EditEnvironment');

const LinkEnvironmentsV2 = () => import(/* webpackChunkName: "mainApp" */ './components/environments/LinkEnvironmentsV2');

const PageNotFoundRoute = { path: 'notFound', component: PageNotFound, replace: true };

export default [
  {
    path: 'services/:serviceCode',
    component: ServicePage,
    props: true,
    children: [
      {
        path: '',
        name: 'environmentList',
        component: EnvironmentList,
        beforeEnter(to, from, next) {
          const serviceCode = to.params.serviceCode;
          const foundConnection = getConnectionByServiceCode(store, serviceCode);
          if (foundConnection) {
            next();
          } else {
            next(PageNotFoundRoute);
          }
        },
        meta: {
          title: () => 'services',
          // environment list route does not inherit workspace query from current route
          requiresWorkspaceQuery: true,
        },
      },
      {
        name: 'createEnvironment',
        path: 'add',
        component: CreateEnvironment,
        meta: {
          protected: ['admin:envs', 'user:envs'],
          // indicates if the above array's UNION or INTERSECT to be taken
          isProtectUnion: true,
          title: () => 'environment',
        },
      },
      {
        name: 'linkEnvironments',
        path: 'linkEnvironments',
        component: LinkEnvironmentsV2,
        meta: {
          protected: ['reseller:connections'],
          title: () => 'link_environments.title',
        },
      },
      {
        name: 'editEnvironment',
        path: ':env/edit',
        component: EditEnvironment,
        meta: {
          title: () => 'environment',
        },
      },
      {
        name: 'environmentMembers',
        path: ':env/members',
        component: EditEnvironmentMembers,
        meta: {
          title: () => 'environment',
        },
      },
      {
        path: ':env',
        component: WorkspacePage,
        name: 'workspace',
        props: true,
        meta: {
          requiresWorkspaceQuery: true,
        },
        children: [
          {
            path: '',
            name: 'emptyWorkspace',
            meta: {
              requiresWorkspaceQuery: true,
            },
          },
          {
            name: 'environmentDashboard',
            path: 'dashboard',
            component: EnvironmentDashboard,
            beforeEnter(to, from, next) {
              const envName = to.params.env;
              const env = store.getters.environments.find(e => e.name === envName);
              if (env) {
                next();
              } else {
                next(PageNotFoundRoute);
              }
            },
            meta: {
              title: () => 'environment',
              requiresWorkspaceQuery: true,
            },
          },
          {
            name: 'resource',
            path: ':viewPath(.*)*',
            component: ResourceView,
            meta: {
              title(params) {
                // TODO emit a label key
                return params.entity;
              },
            },
          },
        ],
        async beforeEnter(to, from, next) {
          const serviceCode = to.params.serviceCode;
          const conn = getConnectionByServiceCode(store, serviceCode);
          if (!conn) {
            return next(PageNotFoundRoute);
          }
          if (store.getters.envOptions.connId !== conn.id) {
            await store.dispatch('loadEnvironmentsForOrgAndService', {
              orgId: store.getters.selectedOrganization.id,
              connId: conn.id,
            });
          }

          const envName = to.params.env;
          const env = store.getters.environments.find(e => e.name === envName);
          if (env) {
            socket.subscribeToEnvironment(env.id);
          }
          await loadLanguageAsync('tour');
          return next();
        },
      },
    ],
  },
];
