<template>
  <cmc-block
    class="cmc-pagination-container"
    padding-horizontal="2xl"
    padding-vertical="m"
  > 
    <cmc-group 
      take-full-width 
      with-vertical-align="center"
    >
      <cmc-grid>
        <cmc-grid-col u="1-3"></cmc-grid-col>
        <cmc-grid-col u="1-3">
          <cmc-align
            at-center
          >
            <cmc-group 
              spacing="none"
              take-full-height
              with-horizontal-align="center"
              class="cmc-pagination"
            >
              <cmc-button
                id="previous-page"
                lhs-icon="chevron-bold-left"
                lhs-icon-svg
                icon-size="m"
                :disabled="page === 1"
                @click="previousPage()"
              />
              <cmc-block 
                class="cmc-pagination-text"
                with-border
              > 
                <cmc-align
                  at-center
                >
                  <cmc-group
                    :with-horizontal-align="'center'"
                    with-vertical-align="center"
                    spacing="none"
                  >
                    <cmc-text
                      :text="`${startIndex} - ${endIndex}&nbsp;`"
                    ></cmc-text>
                    <cmc-text
                      :text="`of ${totalRecords}`"
                      as-description
                    ></cmc-text>
                  </cmc-group>
                </cmc-align>
              </cmc-block>
              <cmc-button
                id="next-page"
                lhs-icon="chevron-bold-right"
                lhs-icon-svg
                icon-size="m"
                :disabled="page === totalPages"
                @click="nextPage()"
              />
            </cmc-group>
          </cmc-align>
        </cmc-grid-col>
        <cmc-grid-col u="1-3">
          <cmc-block
            class="pagination-size"
          >
            <cmc-select
              v-model="currentPageSize"
              :options="pageSizeOptions"
              :allow-empty="false"
              as-number
              @update:modelValue="updatePageSize"
            >
            </cmc-select>
          </cmc-block>
        </cmc-grid-col>
      </cmc-grid>
    </cmc-group>
  </cmc-block>
</template>
  
<script setup lang="ts">
import { computed, defineComponent, onMounted, ref } from 'vue';
import CmcGroup from '../layout/CmcGroup.vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcGrid from '../layout/CmcGrid.vue';
import CmcGridCol from '../layout/CmcGridCol.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcButton from '../buttons/CmcButton.vue';
import CmcText from '../typography/CmcText.vue';
import CmcSelect from '../inputs/CmcSelect.vue';

defineComponent({
  CmcBlock, CmcGrid, CmcGridCol, CmcButton, CmcText, CmcSelect, CmcAlign
})

type Props = {
  /**
   * The page number selected. Defaults to the first page
   */
  currentPage?: number;

  /**
   * Total records displayed in paginable list
   */
  totalRecords: number;

  /**
   * The default number of items to show per page
   */
  pageSize?: number;

  /**
   * The possible numbers of rows to display per page
   */
  options?: number[],   
}


const props = withDefaults(defineProps<Props>(), {
  currentPage: 1,
  options: () => [5, 10, 25, 50, 100, 250],
})

const PAGE_SELECTED = "pageSelected";
const PAGE_SIZE_SELECTED = "pageSizeSelected"
const DEFAULT_PAGE_SIZE = 5;

const page = ref(props.currentPage);
const currentPageSize = ref(getPageSize());

const startIndex = computed(() => (page.value - 1) * currentPageSize.value + 1);

const endIndex = computed(() => {
  const end = page.value * currentPageSize.value;
  return end > props.totalRecords ? props.totalRecords : end;
})

const totalPages = computed(() => Math.ceil(props.totalRecords / currentPageSize.value));
const pageSizeOptions = computed(() => {
  return props.options.map((o) => {
    return {
      value: o,
      label: `${o}`,
      withLabelI18n: false,
      detail: 'pagination.items',
      withDetailI18n: true,
      hideDetailInOptions: true,
    };
  });
});

const emit = defineEmits<{
  /**
   * Emitted when page is changed
   */
  (event: 'pageSelected', value: any): void,

  /**
   * Emitted page size is changed
   */
  (event: 'pageSizeSelected', value: any): void
}>()

onMounted(updatePageSize);

function previousPage() {
  if (page.value > 1) {
    page.value--;
    emit(PAGE_SELECTED, page.value);
  }
}

function nextPage() {
  if (page.value < totalPages.value) {
    page.value++;
    emit(PAGE_SELECTED, page.value);
  }
}

function updatePageSize() {
  page.value = 1;
  localStorage.setItem('pageSize', currentPageSize.value.toString(10));
  emit(PAGE_SELECTED, page.value);
  emit(PAGE_SIZE_SELECTED, currentPageSize.value);
}

function getPageSize(): number {
  let pageSize = props.pageSize || localStorage.getItem('pageSize');
  if (pageSize === null || pageSize === undefined) {
    return DEFAULT_PAGE_SIZE;
  }
  return typeof pageSize === 'string' ? parseInt(pageSize, 10) : pageSize;
}
</script>
  
<style lang="scss" scoped>

.cmc-pagination-container {
  border-bottom: 1px solid var(--ng-list-border);
}

.cmc-pagination {
  min-width: 12rem;
  flex-wrap: nowrap;
  justify-content: center;
  :deep(button) {
    border-radius: 0.1875rem;
    &:not(.disabled):focus {
      border-color: var(--ng-button-border-color);
    }
    &:first-child {
      border-top-right-radius: 0rem;
      border-bottom-right-radius: 0rem;
    }
    &:last-child {
      border-top-left-radius: 0rem;
      border-bottom-left-radius: 0rem;
    }
  }
}

.cmc-pagination-text {
  text-align: center;
  border-radius: 0rem !important;
  border-right-width: 0rem !important;
  border-left-width: 0rem !important;
  min-width: 8rem;
}
  
.pagination-size {
  max-width: 20rem !important;
  min-width: 6rem !important;
  float: right;
  :deep(.cmc-text-as-description) {
    color: var(--ng-text-main) !important;
  }
}
</style>