<template>
  <cmc-accordion
    v-if="formElement.type === 'collapsible'"
    :text="formElement.label"
    with-I18n
    :keep-open="isErrorPresent"
  >
    <div
      v-for="fe in formElement.children"
      :key="fe.field"
    >
      <cmc-ssh-key-input
        v-if="fe.type === 'sshKey'"
        :modelValue="modelValue as Record<string, any>"
        :formElement="fe"
        :as-optional="!fe.required"
        :disabled="fe.disabled || disabled"
        :errors="error"
        @reload="$emit('reload', $event)"
        @update:modelValue="inputChange"
      />
      <cmc-form-element
        v-else
        :model-value="collapsibleValue(fe.field)"
        :formElement="fe"
        :disabled="fe.disabled || disabled"
        :error="collapsibleError(fe.field)"
        @update:modelValue="collapsibleValueChange(fe.field, $event)"
        @reload="$emit('reload', $event)"
        @change="$emit('change', $event)"
      />
    </div>
  </cmc-accordion>
  <cmc-block
    v-else
  >
    <cmc-sensitive-text-input
      v-if="isInput && isSensitive"
      :obscure-initial-value="!formElement.viewable"
      :model-value="modelValue == null ? modelValue : modelValue.toString()"
      :type="formElement.type"
      :label="elemLabel"
      with-label-i18n
      :with-tooltip="elemDescLabel"
      with-tooltip-i18n
      :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
      with-warning-tooltip-i18n
      :with-error-text="errorText"
      :placeholder="elemPlaceholderLabel"
      with-placeholder-i18n
      :disabled="formElement.disabled || disabled"
      @update:modelValue="inputChange"
    />
    <cmc-text-input
      v-else-if="isInput"
      :model-value="modelValue == null ? modelValue : modelValue.toString()"
      :type="formElement.type"
      :label="elemLabel"
      :as-optional="!formElement.required"
      with-label-i18n
      :with-tooltip="elemDescLabel"
      with-tooltip-i18n
      :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
      with-warning-tooltip-i18n
      :with-error-text="errorText"
      :placeholder="elemPlaceholderLabel"
      with-placeholder-i18n
      :disabled="formElement.disabled || disabled"
      @update:modelValue="inputChange"
    />
    <cmc-select
      v-else-if="isSelect"
      :as-tag="formElement.type === 'tags'"
      :as-multi="formElement.type === 'tags' || formElement.type === 'multi-select-checkbox'"
      :label="elemLabel"
      :as-optional="!formElement.required"
      with-label-i18n
      :with-tooltip="elemDescLabel"
      with-tooltip-i18n
      :model-value="selectValue"
      :options="selectOptions"
      :with-placeholder="formElement.disabled || disabled ? elemDisabledDescriptionLabel : elemPlaceholderLabel"
      with-placeholder-i18n
      :allow-empty="!formElement.required"
      :disabled="formElement.disabled || disabled"
      :with-error-text="errorText"
      :withEmptyLabel="formElement.emptyLabel"
      with-empty-label-i18n
      :withReadOnlyEmptyLabel="formElement.readOnlyEmptyLabel"
      @update:modelValue="inputChange"
    />
    <cmc-checkbox-group
      v-else-if="formElement.type === 'checkboxes'"
      :model-value="checkboxGroupModelValue"
      :options="checkboxGroupOptions"
      :label="elemLabel"
      :as-optional="!formElement.required"
      with-label-i18n
      :with-tooltip="elemDescLabel"
      with-tooltip-i18n
      :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
      with-warning-tooltip-i18n
      :with-error-text="errorText"
      :disabled="formElement.disabled || disabled"
      @update:modelValue="inputChange"
    />
    <cmc-checkbox
      v-else-if="formElement.type === 'checkbox'"
      :modelValue="new String(modelValue).toLowerCase() === 'true'"
      :label="elemLabel"
      with-label-i18n
      :with-tooltip="elemDescLabel"
      with-tooltip-i18n
      :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
      with-warning-tooltip-i18n
      :with-error-text="errorText"
      :disabled="formElement.disabled || disabled"
      :as-toggle="formElement.asToggle"
      :reversed="formElement.reversed"
      :as-header="formElement.asToggle"
      heading="h4"
      @update:modelValue="inputChange"
    />
    <cmc-alert
      v-else-if="formElement.type === 'message'"
      :text="formElement.label"
      with-i18n
      with-full-width
      :with-outline="false"
      :as-warning="formElement.alertType === 'WARNING'"
      :color="alertColour"
    />
    <cmc-sensitive-text-area
      v-else-if="isTextArea && !isCodeEditor && isSensitive"
      :obscure-initial-value="!formElement.viewable"
      :model-value="modelValue"
      :label="elemLabel"
      with-label-i18n
      :withTooltip="elemDescLabel"
      with-tooltip-i18n
      :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
      with-warning-tooltip-i18n
      :with-placeholder="elemPlaceholderLabel"
      with-placeholder-i18n
      :with-error-text="errorText"
      :disabled="formElement.disabled || disabled"
      @update:model-value="inputChange"
    />
    <cmc-text-area
      v-else-if="isTextArea"
      :model-value="modelValue"
      :label="elemLabel"
      :as-optional="!formElement.required"
      with-label-i18n
      :withTooltip="elemDescLabel"
      with-tooltip-i18n
      :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
      with-warning-tooltip-i18n
      :with-placeholder="elemPlaceholderLabel"
      with-placeholder-i18n
      :with-error-text="errorText"
      :disabled="formElement.disabled || disabled"
      :as-code-editor="isCodeEditor"
      :language="formElement.language"
      @update:model-value="inputChange"
    />
    <cmc-list-select-form-element
      v-else-if="formElement.type === 'listSelect'"
      :label="elemLabel"
      :with-tooltip="elemDescLabel"
      :category="category"
      :modelValue="modelValue"
      :config="selectedConfig"
      :form-element="formElement"
      :disabled="formElement.disabled || disabled"
      @update:modelValue="inputChange"
      @update:category="categoryChange"
      @update:config="configChange"
    />
    <cmc-ssh-key-input
      v-else-if="formElement.type === 'sshKey'"
      :modelValue="modelValue as Record<string, any>"
      :formElement="formElement"
      :disabled="formElement.disabled || disabled"
      :errors="error"
      @update:modelValue="inputChange"
    />
    <cmc-batch-input
      v-else-if="isBatch"
      :model-value="modelValue == null ? modelValue : modelValue.toString()"
      :form-element="formElement"
      :errors="error"
      :disabled="formElement.disabled || disabled"
      @update:modelValue="inputChange"
      @update:batch="batchOptionChange"
    />
    <!-- Keep this v-else in case none of the conditions are met -->
    <!-- TODO: time-select -->
    <!-- TODO: slider -->
    <!-- TODO: markdownEditor -->
    <!-- TODO: tiles -->
    <!-- TODO: repeatable -->
    <!-- TODO: composite -->
    <!-- TODO: treeselect -->
    <!-- TODO: multi-select-count -->
    <cmc-radio-group
      v-else-if="isRadio"
      :label="formElement.label"
      with-label-i18n
      :withTooltip="elemDescLabel"
      with-tooltip-i18n
      :with-warning-tooltip="disabled ? elemDisabledDescriptionLabel : ''"
      with-warning-tooltip-i18n
      :options="radioGroupOptions"
      :model-value="modelValue"
      @update:modelValue="inputChange"
    />
    <div v-else>
      TODO: {{ formElement.type }}
    </div>
  </cmc-block>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { compactLayout } from '../../form/repeatable';
import CmcBatchInput from '@/components/nextgen/sdk/CmcBatchInput.vue';
import CmcSshKeyInput from './CmcSshKeyInput.vue';
import CmcTextArea from '../inputs/CmcTextArea.vue';
import CmcRadioGroup from '../inputs/CmcRadioGroup.vue'

const DEBOUNCE_TIMEOUT = 800;

type FormOption = {
  interpolation: any;
  type: string;
  name: string;
  is18n?: boolean;
  disabled?: boolean;
  isLeaf?: boolean;
  children?: Object[];
  value: string;
  imageUrl?: string;
  icon?: string;
  titles?: Object[];
  options: FormOption[];
  group?: string;
  detail?: string;
  withDetailI18n?: boolean;
  color?: string;
  isDisabled?: boolean;
  tooltip?: string;
  isTooltipI18n?: boolean;
};
export default defineComponent({
  name: 'CmcFormElement',
  components: {
    CmcSshKeyInput,
    CmcBatchInput,
    CmcTextArea,
    CmcRadioGroup
  },
  props: {
    modelValue: {
      type: [Object, Date, String, Number, Boolean, Array],
      required: false,
    },
    category: {
      type: [Object, Date, String, Number, Boolean, Array],
      required: false
    },
    selectedConfig: {
      type: Object,
      required: false
    },
    formElement: {
      type: Object,
      required: true,
    },
    error: {
      type: [Array, Object],
    },
    disabled: {
      type: Boolean,
    },
  },
  emits: ['update:modelValue', 'update:category', 'changeConfig', 'change', 'reload', 'update:batch'],
  data() {
    return {
      debounce: undefined as number | undefined,
      isOpen: true,
    };
  },
  computed: {
    selectValue() {
      if (this.formElement.type === 'select') {
        return this.modelValue === undefined || this.modelValue === null ? this.modelValue : this.modelValue.toString();
      }
      return (this.modelValue && typeof this.modelValue === 'string') ? this.modelValue.split(',') : this.modelValue || [];
    },
    selectOptions() {
      const optionConv = (o: FormOption) => ({
        label: o.name,
        withLabelI18n: o.is18n,
        value: o.value,
        color: o.color ? o.color : 'light-gray',
        interpolation: o.interpolation,
        detail: o.detail,
        withDetailI18n: true,
        isDisabled: o.isDisabled,
        withTooltip: o.tooltip,
        withTooltipI18n: o.isTooltipI18n
      });
      return this.formElement.options.map((o: FormOption) => {
        if (o.type === 'grouped') {
          return ({
            label: o.name,
            withLabelI18n: o.is18n,
            options: o.options.map(opt => optionConv(opt))
          });
        }
        return [optionConv(o)];
      })
        .reduce((acc: [], g: []) => acc.concat(g), []);
    },
    checkboxGroupModelValue() {
      return this.modelValue == null ? this.modelValue : this.modelValue as string[];
    },
    checkboxGroupOptions() {
      const optionConv = (o: FormOption) => ({
        id: o.value,
        value: o.value,
        label: o.name,
        withI18n: o.is18n,
      });
      return this.formElement.options.map(optionConv);
    },
    radioGroupOptions() {
      const optionConv = (o: FormOption) => ({
        id: o.value,
        value: o.value,
        label: o.name,
        withI18n: o.is18n,
        disabled: o.isDisabled,
        withTooltip: o.tooltip,
        withTooltipI18n: o.isTooltipI18n
      });
      return this.formElement.options.map(optionConv)
    },
    alertColour() {
      switch (this.formElement.alertType) {
        case "INFO":
          return 'blue';
        case "SUCCESS":
          return 'green';
        case "WARNING":
          return 'yellow';
        case "DANGER":
          return 'red';
        }
      return '';
    },
    isRepeatable() {
      return this.formElement.type === 'repeatable';
    },
    isErrorPresent() {
      return this.error !== undefined && this.error !== null && JSON.stringify(this.error) !== '{}'
    },
    isComposite() {
      return this.formElement.type === 'composite';
    },
    isCollapsible() {
      return this.formElement.type === 'collapsible';
    },
    isBatch() {
      return this.formElement.type === 'batch';
    },
    isSshKey() {
      return this.formElement.type === 'sshKey';
    },
    isSlider() {
      return this.formElement.type === 'slider';
    },
    isInput() {
      return ['text', 'password', 'number'].includes(this.formElement.type);
    },
    isSensitive() {
      return this.formElement.sensitive;
    },
    isSelect() {
      return this.formElement.type === 'select' || this.formElement.type === 'tags' || this.formElement.type === 'multi-select-checkbox';
    },
    decimal() {
      return this.formElement.type === 'number' ? this.formElement.decimal : true;
    },
    isTextArea() {
      return this.formElement.type === 'textarea' || this.formElement.type === 'codeEditor';
    },
    isCodeEditor() {
      return this.formElement.type === 'codeEditor';
    },
    isRadio() {
      return this.formElement.type === 'radio';
    },
    repeatAdd() {
      if (this.formElement.type !== 'repeatable') {
        return false;
      }
      return (this.compactRepeatable &&
          (!this.formElement.children || this.formElement.children.length === 0))
        || !this.compactRepeatable;
    },
    compactRepeatable() {
      if (this.formElement.type !== 'repeatable') {
        return false;
      }
      const defaultFe = this.formElement.defaultFormElement;
      return compactLayout(defaultFe.type, defaultFe.children);
    },
    errorList() {
      if (!this.error || JSON.stringify(this.error) === '{}') {
        return [];
      }
      let errorArray;
      if (Array.isArray(this.error)) {
        errorArray = this.error;
      } else {
        errorArray = [this.error];
      }
      errorArray.forEach((e) => {
        if (e.context && !e.context.fieldPath) {
          e.context.fieldPath = Array.isArray(e.context.field) ? e.context.field.join('.') : e.context.field;
        }
      });

      return errorArray;
    },
    subFieldErrors() {
      // Get the list of errors associated to the element and strip the
      // field from the first element.
      // e.g. field: ['ports', '0'] will become field:['0'] since
      // the form element will know the field as '0'
      if (this.errorList.length === 0) {
        return undefined;
      }
      const subErrors = this.errorList.map((e) => {
        const newFieldValue = [...e.context.field];
        newFieldValue.splice(0, 1);
        return {
          ...e,
          context: {
            ...e.context,
            field: newFieldValue,
          },
        };
      });
      return subErrors;
    },
    elemLabel() {
      return this.formElement.label;
    },
    elemDescLabel() {
      return this.formElement.descriptionLabel;
    },
    elemDisabledDescriptionLabel() {
      return this.formElement.disabledDescriptionLabel;
    },
    elemTooltip() {
      return this.formElement.tooltip;
    },
    elemPlaceholderLabel() {
      return !this.disabled ? this.formElement.placeholderLabel : '';
    },
    errorText() {
      return this.errorList.map(err => this.$t(err.context.labelKey, err.context)).join("\n");
    },
  },
  methods: {
    collapsibleValue(field: string) {
      // Ensure modelValue is not null and is an object
      if (this.modelValue && typeof this.modelValue === 'object') {
        // Cast modelValue to an indexable type
        const value = this.modelValue as Record<string, any>;
        // Check if field exists in modelValue and return its value
        if (field in value) {
          return value[field];
        }
      }
      // Return undefined if field is not found or modelValue is not an object
      return undefined;
    },
    // This method retrieves the error message for a specific field in a collapsible form element.
    // It checks if any errors exist and returns the error for the specified field if found.
    collapsibleError(field: string) {
      // Check if error object is undefined, null, or empty; return an empty array if true.
      if (this.error === undefined || this.error === null || JSON.stringify(this.error) === '{}') {
        return [];
      }

      return (this.error as Record<string, any>)[field];
    },
    collapsibleValueChange(field:string, v: Object | Date | String | Number | Boolean | Array<Object>) {
      if (!v) { // Check if the value is empty and fe.required is false
        delete (this.modelValue as Record<string, any>)[field]; // Remove the field from modelValue
      } else {
          (this.modelValue as Record<string, any>)[field] = v;
      }
      this.$emit('update:modelValue', this.modelValue);
      this.$emit('change', this.modelValue);
    },
    categoryChange(v: String) {
      this.$emit('update:category', v);
    },
    configChange(v: Object) {
      this.$emit('changeConfig', v);
      this.reloadSections(this.modelValue as String);
    },
    inputChange(v: Object | Date | String | Number | Boolean | Array<Object>) {
      this.$emit('update:modelValue', v);
      this.reloadSections(v);
    },
    batchOptionChange(v: Record<string, any>) {
      this.$emit('update:batch', v);
      this.reloadSections({});
    },
    doDebounce(func: Function, timeout = DEBOUNCE_TIMEOUT) {
      window.clearTimeout(this.debounce);
      this.debounce = window.setTimeout(() => {
        func();
        this.debounce = undefined;
      }, timeout);
    },
    shouldEmitReload(value: Object | Date | String | Number | Boolean | Array<Object>) {
      // since the checkbox defaults to false, it would skip the first change to 'true'
      // checkboxes default to [], but this.modelValue is still undefined
      // batch also needs an exception as the batch parameters do not modify the modelValue
      return (value !== undefined && value !== null && (value !== this.modelValue))
        || this.formElement.type === 'checkbox' || this.formElement.type === 'checkboxes'
        || this.isBatch || this.formElement.type === 'listSelect'
        || (this.formElement.type === 'select' && value === undefined);
    },
    reloadSections(v: Object | Date | String | Number | Boolean | Array<Object>) {
      if (this.formElement.reloadOnChange && this.shouldEmitReload(v)) {
        const emitChange = () => this.$emit('reload',
          {
            oldValue: this.modelValue,
            field: this.formElement.field,
            formElement: this.formElement,
            value: v,
            sections: this.formElement.sectionsToReload,
            selectedConfig: this.selectedConfig || {},
          }
        );
        if (this.isSlider || this.isInput || this.isTextArea || this.isCollapsible || this.isBatch) {
          this.doDebounce(emitChange);
        } else {
          emitChange();
        }
      } else {
        this.$emit('change', v);
      }
    },
  },
});
</script>
