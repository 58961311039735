import { defineComponent as _defineComponent } from 'vue'
import { createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, withModifiers as _withModifiers, resolveComponent as _resolveComponent, Fragment as _Fragment } from "vue"

const _hoisted_1 = ["title"]
const _hoisted_2 = ["title"]

import { defineComponent, computed } from 'vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcAlign from '../layout/CmcAlign.vue';
import CmcPair from '../layout/CmcPair.vue';
import CmcText from '../typography/CmcText.vue';
import CmcIcon from '../misc/CmcIcon.vue';
import CmcDivider from '../misc/CmcDivider.vue';
import { Size } from '../sizes';

type Props = {
  /**
  * Label of the menu item
  */
  text: string;
  /**
  * True if the text is a label key.
  */
  withI18n?: boolean;
  /**
  * True if the menu item is disabled.
  */
  disabled?: boolean;
  /**
  * True if chevron is visible on the menu item.
  */
  withChevron?: boolean;
  /**
  * True if the menu item has a divider after it
  */
  withDivider?: boolean;
  /**
  * Class of the svg icon
  */
  icon?: string;
  /**
  * Size of the icon 
  */
  iconSize?: Size;
  /**
  * The spacing between menu items
  */
  spacing?: Size;
  /**
  * True if the icon is a svg
  */
  svg?: boolean;
  /**
  * True if the icon is a image url
  */
  img?: boolean;
  /**
  * Link to redirect on click 
  */
  redirectTo?: string;
  /** 
  * Transition to emit a modal or other action
  */
  transition?: string;
  /**
  * True if the menu item text is to be displayed without wrap
  */
  withEllipsisOnTextOverflow: boolean;
  /**
  * True if the title is to be displayed on hover instead of cmc-text tooltip
  */
  withTitleOnHover?: boolean;
  /**
  * Max width of the text wrapper if ellipsis is to be shown
  */
  customTextMaxWidth?: string;
  /**
  * Add padding horizontally.
  */
  horizontalPadding?: Size;
}

export default /*@__PURE__*/_defineComponent({
  __name: 'CmcNavMenuItem',
  props: {
    text: {},
    withI18n: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },
    withChevron: { type: Boolean, default: false },
    withDivider: { type: Boolean, default: false },
    icon: { default: '' },
    iconSize: { default: 'xl' },
    spacing: {},
    svg: { type: Boolean, default: false },
    img: { type: Boolean, default: false },
    redirectTo: {},
    transition: {},
    withEllipsisOnTextOverflow: { type: Boolean, default: false },
    withTitleOnHover: { type: Boolean, default: false },
    customTextMaxWidth: {},
    horizontalPadding: {}
  },
  emits: ["click"],
  setup(__props: any, { emit: __emit }) {

defineComponent({
  CmcBlock,
  CmcPair,
  CmcAlign,
  CmcIcon,
  CmcText,
  CmcDivider
})

const emit = __emit

const iconClass = computed(() => {
  if (!props.svg) {
    return props.icon;
  }
  try {
    require(`@/../public/static/icons/plugins/${props.icon}.svg`);
    return props.icon;
  } catch (_error) {
    return 'default';
  }
})


const props = __props

return (_ctx: any,_cache: any) => {
  const _component_app_link = _resolveComponent("app-link")!

  return (_openBlock(), _createElementBlock(_Fragment, null, [
    _createVNode(CmcBlock, { "with-hover": "" }, {
      default: _withCtx(() => [
        (_ctx.transition !== undefined)
          ? (_openBlock(), _createBlock(CmcBlock, {
              key: 0,
              "padding-vertical": _ctx.spacing,
              "padding-horizontal": _ctx.horizontalPadding,
              onClick: _cache[0] || (_cache[0] = _withModifiers(($event: any) => (emit('click', $event)), ["stop"]))
            }, {
              default: _withCtx(() => [
                _createVNode(CmcPair, {
                  stretchRhs: true,
                  "with-vertical-align": "center",
                  class: "pair-container"
                }, {
                  default: _withCtx(() => [
                    (_ctx.icon)
                      ? (_openBlock(), _createBlock(CmcBlock, {
                          key: 0,
                          "padding-right": "s",
                          "padding-left": "s"
                        }, {
                          default: _withCtx(() => [
                            _createVNode(CmcAlign, { "at-center": "" }, {
                              default: _withCtx(() => [
                                _createVNode(CmcIcon, {
                                  svg: _ctx.svg,
                                  icon: iconClass.value,
                                  img: _ctx.img,
                                  size: _ctx.iconSize,
                                  "as-plugin-icon": ""
                                }, null, 8, ["svg", "icon", "img", "size"])
                              ]),
                              _: 1
                            })
                          ]),
                          _: 1
                        }))
                      : _createCommentVNode("", true),
                    _createVNode(CmcPair, {
                      stretchLhs: true,
                      style: _normalizeStyle({ maxWidth: _ctx.customTextMaxWidth })
                    }, {
                      default: _withCtx(() => [
                        (_ctx.withTitleOnHover)
                          ? (_openBlock(), _createElementBlock("div", {
                              key: 0,
                              title: _ctx.text,
                              class: "title-container"
                            }, [
                              _createVNode(CmcText, {
                                withI18n: _ctx.withI18n,
                                text: _ctx.text,
                                "without-wrap": "",
                                "without-tooltip-on-hover": "",
                                size: "m"
                              }, null, 8, ["withI18n", "text"])
                            ], 8, _hoisted_1))
                          : (_openBlock(), _createBlock(CmcText, {
                              key: 1,
                              withI18n: _ctx.withI18n,
                              text: _ctx.text,
                              "without-wrap": _ctx.withEllipsisOnTextOverflow,
                              size: "m"
                            }, null, 8, ["withI18n", "text", "without-wrap"])),
                        (_ctx.withChevron)
                          ? (_openBlock(), _createBlock(CmcIcon, {
                              key: 2,
                              icon: "chevron-right",
                              svg: "",
                              size: _ctx.iconSize
                            }, null, 8, ["size"]))
                          : _createCommentVNode("", true)
                      ]),
                      _: 1
                    }, 8, ["style"])
                  ]),
                  _: 1
                })
              ]),
              _: 1
            }, 8, ["padding-vertical", "padding-horizontal"]))
          : (_openBlock(), _createBlock(_component_app_link, {
              key: _ctx.text,
              to: _ctx.redirectTo,
              onClick: _cache[1] || (_cache[1] = _withModifiers(($event: any) => (emit('click', $event)), ["stop"]))
            }, {
              default: _withCtx(() => [
                _createVNode(CmcBlock, {
                  "padding-vertical": _ctx.spacing,
                  "padding-horizontal": _ctx.horizontalPadding
                }, {
                  default: _withCtx(() => [
                    _createVNode(CmcPair, {
                      stretchRhs: true,
                      "with-vertical-align": "center",
                      class: "pair-container"
                    }, {
                      default: _withCtx(() => [
                        (_ctx.icon)
                          ? (_openBlock(), _createBlock(CmcBlock, {
                              key: 0,
                              "padding-right": "s",
                              "padding-left": "s"
                            }, {
                              default: _withCtx(() => [
                                _createVNode(CmcAlign, { "at-center": "" }, {
                                  default: _withCtx(() => [
                                    _createVNode(CmcIcon, {
                                      svg: _ctx.svg,
                                      icon: iconClass.value,
                                      img: _ctx.img,
                                      size: _ctx.iconSize,
                                      "as-plugin-icon": ""
                                    }, null, 8, ["svg", "icon", "img", "size"])
                                  ]),
                                  _: 1
                                })
                              ]),
                              _: 1
                            }))
                          : _createCommentVNode("", true),
                        _createVNode(CmcPair, {
                          stretchLhs: true,
                          style: _normalizeStyle({ maxWidth: _ctx.customTextMaxWidth })
                        }, {
                          default: _withCtx(() => [
                            (_ctx.withTitleOnHover)
                              ? (_openBlock(), _createElementBlock("div", {
                                  key: 0,
                                  title: _ctx.text,
                                  class: "title-container"
                                }, [
                                  _createVNode(CmcText, {
                                    withI18n: _ctx.withI18n,
                                    text: _ctx.text,
                                    "without-wrap": "",
                                    "without-tooltip-on-hover": "",
                                    size: "m"
                                  }, null, 8, ["withI18n", "text"])
                                ], 8, _hoisted_2))
                              : (_openBlock(), _createBlock(CmcText, {
                                  key: 1,
                                  withI18n: _ctx.withI18n,
                                  text: _ctx.text,
                                  "without-wrap": _ctx.withEllipsisOnTextOverflow,
                                  size: "m"
                                }, null, 8, ["withI18n", "text", "without-wrap"])),
                            (_ctx.withChevron)
                              ? (_openBlock(), _createBlock(CmcIcon, {
                                  key: 2,
                                  icon: "chevron-right",
                                  svg: "",
                                  size: _ctx.iconSize
                                }, null, 8, ["size"]))
                              : _createCommentVNode("", true)
                          ]),
                          _: 1
                        }, 8, ["style"])
                      ]),
                      _: 1
                    })
                  ]),
                  _: 1
                }, 8, ["padding-vertical", "padding-horizontal"])
              ]),
              _: 1
            }, 8, ["to"]))
      ]),
      _: 1
    }),
    (_ctx.withDivider)
      ? (_openBlock(), _createBlock(CmcDivider, { key: 0 }))
      : _createCommentVNode("", true)
  ], 64))
}
}

})