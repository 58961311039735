<template>
  <cmc-block
    :padding-bottom="'2xl'"
  >
    <cmc-grid 
      :style="{'max-width': maxWidth}"
    >
      <cmc-grid-col
        v-for="(child, index) in formElement?.formElements"
        :key="index"
        :u="columnsSize[index]"
        :class="{ 'last-element': index === formElement?.formElements.length - 1 }"
      >
        <cmc-form-element
          v-model="entityReference[child.field]"
          :formElement="child"
          :disabled="child.disabled"
          :error="errors[child.field]"
          @change="emit('change', child)"
          @reload="emit('reload', $event)"
          @update:modelValue="emit('update:entity', child.field, $event)"
        />
      </cmc-grid-col>
    </cmc-grid>
  </cmc-block>
</template>

<script setup lang="ts">
import { computed, defineComponent, reactive } from 'vue';
import CmcBlock from '../layout/CmcBlock.vue';
import CmcGrid from '../layout/CmcGrid.vue';
import CmcGridCol from '../layout/CmcGridCol.vue';
import CmcFormElement from '../sdk/CmcFormElement.vue';
import { FormElement } from '@/app/Main/Services/components/models';
import { ColSize } from '../layout/types';

const GRID_SIZE_MAP : Map<number, Map<string, string[]>> = new Map([
    [
        1, new Map([
        ['CENTER', ['12-12']],
        ['STRETCH_LHS', ['12-12']],
        ['STRETCH_RHS', ['12-12']]
    ])],
    [
        2, new Map([
        ['CENTER', ['6-12', '6-12']],
        ['STRETCH_LHS', ['9-12', '3-12']],
        ['STRETCH_RHS', ['3-12', '9-12']]
    ])],
    [
        3, new Map([
        ['CENTER', ['4-12', '4-12', '4-12']],
        ['STRETCH_LHS', ['6-12', '4-12', '2-12']],
        ['STRETCH_RHS', ['2-12', '4-12', '6-12']]
    ])]
    // Add more sizes here once we have the use-case.
]);

const MAX_WIDTH_MAP: Map<number, number> = new Map([
    [1, 24],
    [2, 24],
    [3, 24]
    // Add more width here once we have the use-case.
]);

defineComponent({
  CmcBlock,
  CmcGrid,
  CmcGridCol,
  CmcFormElement
});

/**
 * Align content of the row as returned by the SDK.
 */
type AlignContent = 'CENTER' | 'STRETCH_LHS' | 'STRETCH_RHS';

type Props = {
    /**
     * HTML element id
     */
    id?: string;

    /**
     * The entity
     */
    entity: Record<string,Object>;

    /**
     * The row formElement that contains the formElements to be displayed
     */
    formElement?: FormElement;

    /**
     * The errors object that contains the errors for each field in the row
     */
    errors?: Object; 
}

const props = defineProps<Props>();

const emit = defineEmits<{
  /**
   * An element in the Row has changed.
   */
  (event: 'change', formElement: FormElement): void,

  /**
   * An element in the Row triggered a ReloadOnChange event.
   */
  (event: 'reload', $event: any): void,

  /**
   * An element in the row has updated the entity.
   */
  (event: 'update:entity', field: string, value: object): void,
}>();

// To avoid mutating the props directly, we keep a reactive reference to the props.
const entityReference = reactive(props.entity);

/**
 * Computed variable of the size of each column based on the number of elements in the row.
 */
const columnsSize = computed<ColSize[]>(() => {
    const num = props.formElement?.formElements.length ?? 1;
    const alignContent: AlignContent = props.formElement?.alignContent || 'CENTER';
    const columns = GRID_SIZE_MAP.get(num)?.get(alignContent) || ['12-12'];
    return columns as ColSize[];
});

/**
 * Computed variable of the max width (in Rem) of the row based on the number of elements in the row.
 */
const maxWidth = computed<string>(() => {
    return MAX_WIDTH_MAP.get(props.formElement?.formElements.length ?? 1) + 'rem';
});
</script>

<style scoped lang="scss">

.last-element {
  padding-right: 0rem;
}
</style>