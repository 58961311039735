<template>
  <div 
    :id="id" 
    :class="['cmc-grid-col', classes, { [`cmc-contained-in-${containedIn}`]: containedIn && contextual, [`cmc-grid-col-size-${size}`]: size}]"
    @click="emit('click', $event)"
  >
    <!--
      @slot Content of the grid column
    -->
    <slot></slot>
  </div>
</template>

<script setup lang="ts">
import { computed, inject } from 'vue';
import { CMC_CONTAINED_IN, CMC_CONTEXTUAL_GRID, CMC_FORM } from '../constants';
import { ColSize } from './types';

export type CmcGridColProps = {
  /**
   * HTML element id
   */
  id?: string;
  /**
   * Create an unresponsive grid. The size specified here will always be the size of the column.
   */
  u?: ColSize;
  /**
   * Create a responsive grid. Specify size for small screen.
   */
  sm?: ColSize;
  /**
   * Create a responsive grid. Specify size for medium screen.
   */
  md?: ColSize;
  /**
   * Create a responsive grid. Specify size for large screen.
   */
  lg?: ColSize;

  /**
   * Creates a responsive grid using predefined rules based on the parent container. 
   * The outer grid needs to be set as contextual.
   * The column size should be the size you would put in desktop mode (e.g. lg)
   */
  size?: ColSize;

}
const props = defineProps<CmcGridColProps>()

const gcd = (a: number, b: number) : number => {
  if (b === 0) {
    return a;
  }
  return gcd(b, a % b);
}

const reduceFraction = (numerator : number, denominator : number) =>  {
  const commonDivisor = gcd(numerator, denominator);
  const reducedNumerator = numerator / commonDivisor;
  const reducedDenominator = denominator / commonDivisor;
  
  return [reducedNumerator, reducedDenominator];
}

const contextual = inject(CMC_CONTEXTUAL_GRID, false)
const containedIn = inject(CMC_CONTAINED_IN, '');
type SimpleSize = {[key: string]: { sm: ColSize, md: ColSize}};
const sizeMap: {[key:string]: SimpleSize}= {
  [CMC_FORM]: {
    '1-12': { md: '1-6', sm: '1-2' },
    '1-6':  { md: '1-4', sm: '1-2' },
    '1-4':  { md: '1-3', sm: '1-1' },
    '1-3':  { md: '1-2', sm: '1-1' },
    '1-2':  { md: '1-1', sm: '1-1' },
    '1-1':  { md: '1-1', sm: '1-1' },
  },
}
const adjustedSizes = computed(() => {
  let { u, sm, md, lg, size } = props;
  if (contextual && containedIn && sizeMap[containedIn] && props.size) {
    const aSize = sizeMap[containedIn][props.size]
    if (aSize) {
      sm = aSize.sm;
      md = aSize.md;
      lg = props.size;
    }
  }
  return { u, sm, md, lg, size };
})

const classes = computed(() => {
  const { u, size, sm, md, lg } = adjustedSizes.value;
  return [[u || size || sm || md || lg, ''], [sm, '-sm'], [md, '-md'], [lg, '-lg']]
    .filter(e => e[0])
    .map(e => [(e[0] === '1' ? '1-1' : e[0]), e[1]] as [string, string])
    .map(e => [e[0].split('-').map(sp => Number(sp)), e[1]] as [number[], string])
    .map(e => [reduceFraction(e[0][0], e[0][1]), e[1]])
    .map(e => `pure-u${e[1]}-${e[0][0]}-${e[0][1]}`)
})


const emit = defineEmits<{
  /**
   * Emitted when block was clicked
   * @arg HTML click event
   */
  (event: 'click', payload: any): void
}>()


</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss';
@use '../sizes.scss';

.cmc-grid-col {
  box-sizing: border-box;
}

.cmc-contained-in-cmc-form {
  padding-right: 0.5rem;
  &.cmc-grid-col-size-1-12 {
    @include mixins.screen-md {
      min-width: 8rem;
    }
    @include mixins.screen-lg {
      min-width: 0;
    }
  }

  &.cmc-grid-col-size-1-6 {
    @include mixins.screen-md {
     // min-width: 16rem;
    }
    @include mixins.screen-lg {
     // min-width: 0;
    }
  }

  &.cmc-grid-col-size-1-4 {
    @include mixins.screen-md {
      //min-width: 16rem;
    }
    @include mixins.screen-lg {
      //min-width: 0;
    }
  }

  &.cmc-grid-col-size-1-3 {
    @include mixins.screen-md {
      //min-width: 32rem;
    }
    @include mixins.screen-lg {
      //min-width: 0;
    }
  }

  &.cmc-grid-col-size-1-2 {
    @include mixins.screen-md {
      //min-width: 32rem;
    }
    @include mixins.screen-lg {
      //min-width: 0;
    }
  }
}
</style>
