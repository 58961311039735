<template>
  <base-loader v-if="loading"></base-loader>

  <div 
    v-else 
    :class="isRepeatMobile ? '' : 'align-to-toggle' "
  >
    <form-page
      title="authentication.new_user_config.manage_user_create"
      :disabled="!canSubmit"
      :defaultBack="{ name: 'providers-list' }"
      submitLabel="save"
      @submit="submit"
    >
      <template #title>
        <div class="title-plus-logo">
          <base-image
            v-if="identityProvider.logo"
            class="logo"
            :src="identityProvider.logo"
            :altText="identityProvider.displayName"
          />
          <span class="titleName">{{ identityProvider.displayName }} - {{ $t('authentication.new_user_config.manage_user_create') }}</span>
        </div>
      </template>
      <form-row>
        <div class="realigned-toggle">
          <cmc-group 
            spacing="3xs" 
            withVerticalAlign="center"
          >
            <cmc-block>
              <cmc-checkbox
                v-model="newUserConfig.enabled"
                asToggle
                reversed
              />
            </cmc-block>
            <cmc-block>
              <cmc-text
                :text="$t('authentication.new_user_config.enabled')"
                as-label
                :with-tooltip="$t('authentication.new_user_config.enable_tooltip')"
              ></cmc-text>
            </cmc-block>
          </cmc-group>
        </div>
      </form-row>
      <cmc-stack spacing="s">
        <cmc-block>
          <h3>
            {{ $t('authentication.new_user_config.organization_mapping') }}
          </h3>
          <div class="field-wrapper">
            <form-row label="authentication.new_user_config.match_claim">
              <base-input
                v-model="newUserConfig.matchClaimField"
                type="text"
                :maxLength="200"
                :disabled="!newUserConfig.enabled"
                :placeholder="$t('authentication.new_user_config.input_field_place_holder')"
              />
            </form-row>
            <form-row label="authentication.new_user_config.map_org">
              <base-select
                v-model="newUserConfig.matchOrgField"
                :items="orgFields"
                :disabled="!newUserConfig.enabled"
                :placeholder="$t('authentication.new_user_config.select_field_place_holder')"
              />
            </form-row>
          </div>
        </cmc-block>
        <cmc-block>
          <h3>{{ $t('authentication.new_user_config.role_mapping') }}</h3>
          <div>
            <form-row label="authentication.new_user_config.default_role">
              <base-select
                v-model="newUserConfig.defaultRole.id"
                :items="roleList"
                :disabled="!newUserConfig.enabled"
                :placeholder="$t('authentication.new_user_config.select_field_place_holder')"
              />
            </form-row>
            <form-row label="authentication.new_user_config.match_claim">
              <base-input
                v-model="newUserConfig.claimRoleField"
                type="text"
                :maxLength="200"
                :disabled="!newUserConfig.enabled"
                :placeholder="$t('authentication.new_user_config.input_field_place_holder')"
              />
            </form-row>
          </div>
          <template v-if="isRepeatMobile">
            <div
              v-for="(mapping, index) in newUserConfig.roleMappings"
              :key="index"
              class="repeatMobile"
            >
              <form-row
                label="authentication.new_user_config.claim_field_value"
                :error="$t(errors[`roleMappings.${index}.fieldValue`])"
              >
                <base-input
                  v-model="mapping.fieldValue"
                  type="text"
                  :maxLength="200"
                  :disabled="!newUserConfig.enabled"
                  :placeholder="$t('authentication.new_user_config.input_field_value_place_holder')"
                />
              </form-row>
              <form-row
                label="authentication.new_user_config.map_role"
              >
                <base-select
                  v-model="mapping.roleId"
                  :items="roleList"
                  :disabled="!newUserConfig.enabled"
                  :placeholder="$t('authentication.new_user_config.select_role_place_holder')"
                  :clearable="true"
                />
              </form-row>
              <div class="add-remove-toggle-wrapper">
                <base-button
                  v-if="!hideRemove && (index > 0 || newUserConfig.roleMappings.length > 1)"
                  :rounded="false"
                  :aria-label="$t('remove')"
                  class="add-remove-toggle"
                  @click="removePrimaryRoleMapping(index)"
                >
                  <base-icon icon="fa fa-minus" />
                </base-button>
                <base-button
                  v-if="!hideAdd && index === newUserConfig.roleMappings.length -1"
                  :rounded="false"
                  :aria-label="$t('add')"
                  class="add-remove-toggle"
                  @click="addPrimaryRoleMapping(index +1)"
                >
                  <base-icon icon="fa fa-plus" />
                </base-button>
              </div>
            </div>
          </template>
          <template v-if="!isRepeatMobile">
            <div
              v-if="newUserConfig.roleMappings.length !== 0"
              class="role-mapping-container"
            >
              <div class="field-wrapper">
                <form-row
                  label="authentication.new_user_config.claim_field_value"
                  class="labelNoPadding"
                />
                <form-row
                  label="authentication.new_user_config.map_role"
                  class="labelNoPadding"
                />
                <div />
              </div>
              <div
                v-for="(mapping, index) in newUserConfig.roleMappings"
                :key="index"
                class="field-wrapper field-spacing"
              >
                <base-input
                  v-model="mapping.fieldValue"
                  type="text"
                  :maxLength="200"
                  :disabled="!newUserConfig.enabled"
                  :placeholder="$t('authentication.new_user_config.input_field_value_place_holder')"
                  :error="$t(errors[`roleMappings.${index}.fieldValue`])"
                  class="error-wrapper"
                />
                <base-select
                  v-model="mapping.roleId"
                  :items="roleList"
                  :disabled="!newUserConfig.enabled"
                  :placeholder="$t('authentication.new_user_config.select_role_place_holder')"
                  :clearable="true"
                />
                <div class="add-remove-toggle-wrapper">
                  <base-button
                    v-if="!hideRemove && (index > 0 || newUserConfig.roleMappings.length > 1)"
                    :rounded="false"
                    :aria-label="$t('remove')"
                    class="add-remove-toggle"
                    @click="removePrimaryRoleMapping(index)"
                  >
                    <base-icon icon="fa fa-minus" />
                  </base-button>
                  <base-button
                    v-if="!hideAdd && index === newUserConfig.roleMappings.length -1"
                    :rounded="false"
                    :aria-label="$t('add')"
                    class="add-remove-toggle"
                    @click="addPrimaryRoleMapping(index +1)"
                  >
                    <base-icon icon="fa fa-plus" />
                  </base-button>
                </div>
              </div>
            </div>
          </template>

          <form-row>
            <div>
              <cmc-group 
                spacing="3xs" 
                withVerticalAlign="center"
              >
                <cmc-block>
                  <cmc-checkbox
                    v-model="newUserConfig.primaryRoleRefreshEnabled"
                    :disabled="!newUserConfig.enabled"
                    asToggle
                    reversed
                  />
                </cmc-block>
                <cmc-block>
                  <cmc-text
                    :text="$t('authentication.new_user_config.primary_role_refresh_enabled')"
                    as-label
                    :with-tooltip="$t('authentication.new_user_config.primary_role_refresh_enabled_tooltip')"
                  ></cmc-text>
                </cmc-block>
              </cmc-group>
            </div>
          </form-row>
        </cmc-block>
        <cmc-block>
          <h3>{{ $t('authentication.new_user_config.additional_role_mapping') }}</h3>
          <form-row label="authentication.new_user_config.match_claim">
            <base-input
              v-model="newUserConfig.additionalRoleClaimField"
              type="text"
              :maxLength="200"
              :disabled="!newUserConfig.enabled"
              :placeholder="$t('authentication.new_user_config.input_field_place_holder')"
            />
          </form-row>
          <template v-if="!isRepeatMobile">
            <div 
              v-if="newUserConfig.additionalRoleMappings.length !== 0"
              class="role-mapping-container"
            >
              <div class="field-wrapper">
                <form-row
                  label="authentication.new_user_config.claim_field_value"
                  class="labelNoPadding"
                />
                <form-row
                  label="authentication.new_user_config.map_additional_role"
                  class="labelNoPadding"
                />
                <div />
              </div>
              <div
                v-for="(mapping, index) in newUserConfig.additionalRoleMappings"
                :key="index"
                class="field-wrapper field-spacing"
              >
                <base-input
                  v-model="mapping.fieldValue"
                  type="text"
                  :maxLength="200"
                  :disabled="!newUserConfig.enabled"
                  :placeholder="$t('authentication.new_user_config.input_field_value_place_holder')"
                  :error="$t(errors[`additionalRoleMappings.${index}.fieldValue`])"
                  class="error-wrapper"
                />
                <base-input
                  v-model="mapping.roleName"
                  type="text"
                  :maxLength="200"
                  :disabled="!newUserConfig.enabled"
                  :placeholder="$t('authentication.new_user_config.additional_role_mapping_name_place_holder')"
                  :error="$t(errors[`additionalRoleMappings.${index}.roleName`])"
                  class="error-wrapper"
                />
                <div class="add-remove-toggle-wrapper">
                  <base-button
                    v-if="!hideRemove && (index > 0 || newUserConfig.additionalRoleMappings.length > 1)"
                    :rounded="false"
                    :aria-label="$t('remove')"
                    class="add-remove-toggle"
                    @click="removeAdditionalRoleMapping(index)"
                  >
                    <base-icon icon="fa fa-minus" />
                  </base-button>
                  <base-button
                    v-if="!hideAdd && index === newUserConfig.additionalRoleMappings.length -1"
                    :rounded="false"
                    :aria-label="$t('add')"
                    class="add-remove-toggle"
                    @click="addAdditionalRoleMapping(index +1)"
                  >
                    <base-icon icon="fa fa-plus" />
                  </base-button>
                </div>
              </div>
            </div>
          </template>
          
          <template v-if="isRepeatMobile">
            <div
              v-for="(mapping, index) in newUserConfig.additionalRoleMappings"
              :key="index"
              class="repeatMobile"
            >
              <form-row
                label="authentication.new_user_config.claim_field_value"
                :error="$t(errors[`additionalRoleMappings.${index}.fieldValue`])"
              >
                <base-input
                  v-model="mapping.fieldValue"
                  type="text"
                  :maxLength="200"
                  :disabled="!newUserConfig.enabled"
                  :placeholder="$t('authentication.new_user_config.input_field_place_holder')"
                />
              </form-row>
              <form-row
                label="authentication.new_user_config.map_additional_role"
              >
                <base-input
                  v-model="mapping.roleName"
                  type="text"
                  :maxLength="200"
                  :disabled="!newUserConfig.enabled"
                  :placeholder="$t('authentication.new_user_config.additional_role_mapping_name_place_holder')"
                />
              </form-row>
              <div class="add-remove-toggle-wrapper">
                <base-button
                  v-if="!hideRemove && (index > 0 || newUserConfig.additionalRoleMappings.length > 1)"
                  :rounded="false"
                  :aria-label="$t('remove')"
                  class="add-remove-toggle"
                  @click="removeAdditionalRoleMapping(index)"
                >
                  <base-icon icon="fa fa-minus" />
                </base-button>
                <base-button
                  v-if="!hideAdd && index === newUserConfig.additionalRoleMappings.length -1"
                  :rounded="false"
                  :aria-label="$t('add')"
                  class="add-remove-toggle"
                  @click="addAdditionalRoleMapping(index +1)"
                >
                  <base-icon icon="fa fa-plus" />
                </base-button>
              </div>
            </div>
          </template>
          
          <form-row>
            <div>
              <cmc-group 
                spacing="3xs" 
                withVerticalAlign="center"
              >
                <cmc-block>
                  <cmc-checkbox
                    v-model="newUserConfig.additionalRoleRefreshEnabled"
                    :disabled="!newUserConfig.enabled"
                    asToggle
                    reversed
                  />
                </cmc-block>
                <cmc-block>
                  <cmc-text
                    :text="$t('authentication.new_user_config.additional_role_refresh_enabled')"
                    as-label
                    :with-tooltip="$t('authentication.new_user_config.additional_role_refresh_enabled_tooltip')"
                  ></cmc-text>
                </cmc-block>
              </cmc-group>
            </div>
          </form-row>
        </cmc-block>
      </cmc-stack>
    </form-page>
  </div>
</template>

<script>
import apis from '@/utils/apis';
import notify from '@/utils/notify';
import { orgSwitchNavigationMixin } from '@/mixins/orgSwitchNavigationGuard';
import { isMobileMode } from '@/utils';
import { mapGetters } from 'vuex';


export default {
  name: 'IdentityProviderUserConfig',
  components: {},
  mixins: [orgSwitchNavigationMixin],
  props: {
    id: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      newUserConfig: {
          enabled: false,
          defaultRole: {},
          roleMappings: [],
          additionalRoleMappings: [],
          primaryRoleRefreshEnabled: false,
          additionalRoleRefreshEnabled: false
        },
      identityProvider: {},
      orgFields: [],
      roleList: [],
      loading: true,
      isRepeatMobile: isMobileMode(),
      errors: {},
    };
  },
  computed: {
    ...mapGetters(['selectedOrganization', 'locale']),
    canSubmit() {
        const config = this.newUserConfig;

        return !config.enabled || (config.defaultRole?.id &&
            config.matchOrgField &&
            config.matchClaimField &&
            this.isCompletelyFilledMappings());
    },
    hideRemove(){
        return !this.newUserConfig.enabled;
    },
    hideAdd() {
      return !this.newUserConfig.enabled;
    },
  },
  mounted() {
    this.$nextTick(() =>
      window.addEventListener('resize', () => {
        this.isRepeatMobile = isMobileMode();
      }));
  },
  async created() {
    await this.fetchIdp();
    await this.fetchIdpUserConfig();
    await this.fetchOrgFields();
    await this.fetchRoles();
    this.loading = false;
  },
  methods: {
    isCompletelyFilledMappings(){
      return this.hasFilledPrimaryRoleMappings() && this.hasFilledAdditionalRoleMappings();
    },
    hasFilledPrimaryRoleMappings() {
      return (
        !this.newUserConfig.claimRoleField && this.arePrimaryRoleMappingFieldsEmpty()
      ) || (
        (this.newUserConfig.claimRoleField && this.newUserConfig.claimRoleField !== '') &&
        this.newUserConfig.roleMappings.filter(m => !m.roleId || !m.fieldValue).length === 0
      );
    },
    arePrimaryRoleMappingFieldsEmpty() {
      return this.newUserConfig.roleMappings.filter(m => m.roleId || m.fieldValue).length === 0;
    },
    hasFilledAdditionalRoleMappings() {
      return (
          !this.newUserConfig.additionalRoleClaimField && this.areAdditionalRoleMappingFieldsEmpty()
        ) || (
          (this.newUserConfig.additionalRoleClaimField && this.newUserConfig.additionalRoleClaimField !== '') &&
          this.newUserConfig.additionalRoleMappings.filter(m => !m.roleName || !m.fieldValue).length === 0
      );
    },
    areAdditionalRoleMappingFieldsEmpty() {
      return this.newUserConfig.additionalRoleMappings.filter(m => m.roleName || m.fieldValue).length === 0;
    },
    removePrimaryRoleMapping(index) {
      this.removeFormElement(index, this.newUserConfig.roleMappings);
    },
    removeAdditionalRoleMapping(index) {
      this.removeFormElement(index, this.newUserConfig.additionalRoleMappings);
    },
    removeFormElement(index, mappings) {
        const getNewFieldIndex = fieldIndex => fieldIndex - 1;
        const inAddRange = fieldIndex => fieldIndex >= index;

        const errorsToRemove = this.getErrorToRemove(index);
        const errorsToAdd = this.getErrorToAdd(inAddRange, getNewFieldIndex);

        const newErrors = []
        Object.entries(this.errors).forEach(err => {
          if (!errorsToRemove.includes(err[0])) {
            newErrors[err[0]] = err[1];
          }
        });
        errorsToAdd.forEach(err => newErrors[err.field] = err.value);

        mappings.splice(index,1);
        // If you remove the last element, make sure we keep one item in the list to stop the mappings from disappearing.
        if (mappings.length === 0) {
          mappings.push({});
        }
        this.errors = newErrors;
    },
    addPrimaryRoleMapping(index) {
      this.addFormElement(index, this.newUserConfig.roleMappings);
    },
    addAdditionalRoleMapping(index) {
      this.addFormElement(index, this.newUserConfig.additionalRoleMappings);
    },
    addFormElement(index, collection){
        const getNewFieldIndex = fieldIndex => fieldIndex + 1;
        const inAddRange = fieldIndex => fieldIndex >= index ;

        const errorsToRemove = this.getErrorToRemove(index);
        const errorsToAdd = this.getErrorToAdd(inAddRange, getNewFieldIndex);

        const newErrors = []
        Object.entries(this.errors).forEach(err => {
          if (!errorsToRemove.includes(err[0])) {
            newErrors[err[0]] = err[1];
          }
        });
        errorsToAdd.forEach(err => newErrors[err.field] = err.value);

        collection.splice(index,0,{});
        this.errors = newErrors;
    },

    getErrorToRemove(index) {
      // Gettings the errors to remove.
      // We need to remove all errors for the fields being remove
      // and after since we need to recompute them
      const setToRemove = [];

      Object.keys(this.errors).forEach(err => {
        const errorField  = err.split('.');
        if (errorField[0] == 'roleMappings'){
          const fieldIndex = parseInt(errorField[1], 10);
          if (fieldIndex >= index) {
            setToRemove.push(err);
          }
        }
      });
      return setToRemove;
    },
    getErrorToAdd(inAddRange, getNewFieldIndex) {
      // Gettings the errors to add back.
      // Since all the fields will change id, we need to recompute
      // them with the correct one and add them back.
      const setToAdd = [];

      Object.keys(this.errors).forEach(err => {
        const errorField  = err.split('.');
        if (errorField[0] == 'roleMappings'){
          const fieldIndex = parseInt(errorField[1], 10);
          if (inAddRange(fieldIndex)) {
            const newFieldName = errorField[0] + "." + getNewFieldIndex(fieldIndex) + "." + errorField.slice(2).join(".");
            setToAdd.push({field: newFieldName , value: this.errors[err]})
          }
        }
      });
      return setToAdd;
    },
    async submit() {
      let resp = {};
      const config = {
        ...this.newUserConfig,
        roleMappings: this.arePrimaryRoleMappingFieldsEmpty() ? [] : this.newUserConfig.roleMappings,
        additionalRoleMappings: this.areAdditionalRoleMappingFieldsEmpty() ? [] : this.newUserConfig.additionalRoleMappings
      };
      resp = await apis.identityProviders.updateNewUserConfig(this.identityProvider.id, config);
      if (resp.status === 200) {
        notify.success(
          this.$t('authentication.new_user_config.update_operation.success', { name: this.identityProvider.displayName })
        );
        this.$router.push({ name: 'providers-list' });
      } else if (resp.errors) {

        resp.errors.filter(err => err.context && err.context.field)
        .forEach((err) => {
          const errorMsg = err.context.labelKey || err.message || err.errorCode;
          this.errors[err.context.field] = errorMsg;
        });
        notify.error(this.$t('authentication.new_user_config.update_operation.error'));
      }
    },
    async fetchIdp() {
      const identityProvider = await apis.identityProviders.find(this.id);
      if (identityProvider.status !== 200 || !identityProvider.data) {
        notify.error(this.$t('unexpected_error'));
      } else {
        this.identityProvider = { ...identityProvider.data };
      }
    },
    async fetchIdpUserConfig() {
      const userConfig = await apis.identityProviders.getNewUserConfig(this.id);
      if (userConfig.status !== 200) {
        notify.error(this.$t('unexpected_error'));
      } else {
        if (userConfig.data) {
          this.newUserConfig = { ...userConfig.data };
          if (!this.newUserConfig.defaultRole) {
            this.newUserConfig.defaultRole = {};
          }
          if (!this.newUserConfig.roleMappings || this.newUserConfig.roleMappings.length === 0) {
              this.newUserConfig.roleMappings = [{}];
          }
          if (!this.newUserConfig.additionalRoleMappings || this.newUserConfig.additionalRoleMappings.length === 0) {
            this.newUserConfig.additionalRoleMappings = [{}];
          }
        }
      }
    },
    async fetchOrgFields() {
      let options = [];
      options.push({ value: 'name', label: 'authentication.new_user_config.org_field.name' }),
      options = options.concat(await this.getCustomFields());
      this.orgFields = options;
    },
    async getCustomFields() {
      const resp = await apis.organizations.getCustomFields(this.selectedOrganization.id)
      if (!resp || resp.status !== 200) {
        notify.error(this.$t('unexpected_error'));
        return [];
      }
      return resp.data.map(cf => ({
        value: cf.field,
        display: cf.nameTranslations[this.locale],
      }));
    },
    async fetchRoles() {
      const resp = await apis.roles.list({
        qs: {
          organization_id: this.selectedOrganization.id,
          system_only: true,
          reverse_strength: false,
        },
      });
      if (!resp || resp.status !== 200) {
        notify.error(this.$t('unexpected_error'));
        return;
      }
      this.roleList = resp.data.filter(ar => ar.name !== 'operator')
        .filter(ar => ar.defaultScope !== 'ENV')
        .filter(ar => ar.isFixed)
        .map( role => ({
          value: role.id,
          label: `fixed_roles.${role.name}.name`,
        }));
    },
  },
};
</script>

<style scoped lang="scss">
@use '@/styles/mixins.scss';

.logo {
    max-height: 30px;
    max-width: 30px;
}

.title-plus-logo {
  display: flex;
  align-items: center;
}
.titleName {
  padding-left: 10px;
}

h3 {
  font-weight: normal;
  font-family: "Inter";
  letter-spacing: -0.01rem;
}

h3, h4 {
  text-align: start;
  margin-block-end: 0;
}

h4 {
  flex:1
}

.field-wrapper {
  @include mixins.not-phone {
    display:flex;
    flex-direction: row;
    > div {
      flex: 1;
      &:not(:last-child) {
        margin-right: 8px ;
      }
      &:not(:first-child) {
        margin-left: 8px ;
      }
    }
  }
}

.repeatMobile {
  margin-bottom: 3px;
  padding-bottom: 5px;
  border-bottom: 1px solid var(--primary-dark);
}

.add-remove-toggle-wrapper {
  height: 2.5rem;
  display: flex;
  @include mixins.not-phone {
   margin: 0 0px 0 15px;
  }
  button:first-child {
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
  }
  button:last-child {
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
  }
}

.add-remove-toggle {
  min-width: 52px;
}

.labelNoPadding{
    padding-bottom: unset;
}
.error-wrapper{
  :deep(.error-label) {
    margin-top: 5px;
    font-size: 12px;
    text-align: left;
  }
}
.field-spacing {
  &:not(:last-child) {
    margin-bottom: 5px;
  }
}

.addButton {
  margin-bottom: 5px;
}
.roleMappings {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.role-mapping-container {
  padding-bottom: 1em;
}

</style>

<style lang="scss">

.action-icon {
  font-size: 14px;
}

.align-to-toggle .base-form {
  margin-left: 38px;
}

.align-to-toggle .realigned-toggle {
  margin-left: -38px;
}

.realigned-toggle .cmc-text {
  color: var(--text);
}

</style>

