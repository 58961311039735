import { mapGetters } from 'vuex';

export const organizationFeatures = {
  data() {
    return {
      availableFeatures: ['MONETIZATION'],
    };
  },
  computed: {
    ...mapGetters(['organizationFeatures']),
    featuresAccess() {
      return this.availableFeatures.reduce((acc, curr) => {
        acc[curr] = this.organizationFeatures.includes(curr);
        return acc;
      }, {});
    },
  },
};
